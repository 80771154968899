var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.productStatusRequest.value === 'loading-getProducts',"loading-text":"Đang tải dữ liệu","items":_vm.products,"item-key":"id","show-select":""},on:{"click:row":function($event){return _vm.openModalProduct($event)}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){return _vm.copyToClipboard(item.code)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}},{key:"item.ttkd",fn:function(ref){
var item = ref.item;
return [(item.ttkd)?_c('v-icon',{attrs:{"size":"16","color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"size":"16","color":"red lighten-3"}},[_vm._v("mdi-close")])]}},{key:"item.ttrm",fn:function(ref){
var item = ref.item;
return [(item.ttrm)?_c('v-icon',{attrs:{"size":"16","color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"size":"16","color":"red lighten-3"}},[_vm._v("mdi-close")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.PRODUCT_STATUS[item.status].label)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }