<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Sản phẩm</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div class="">
        <div class="font-weight-bold mb-2">Loại sản phẩm</div>
        <v-autocomplete
          v-model="selectedProductsTypes"
          :items="productTypes"
          chips
          class="tp-filter-autocomplete"
          deletable-chips
          dense
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn loại sản phẩm"
          single-line
          small-chips
        ></v-autocomplete>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Thương hiệu</div>
        <v-autocomplete
          v-model="selectedBrands"
          :items="brands"
          chips
          class="tp-filter-autocomplete"
          deletable-chips
          dense
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          label="Outlined"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn thương hiệu"
          single-line
          small-chips
        ></v-autocomplete>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Tình trạng ra mắt</div>
        <v-checkbox
          v-model="selectedLaunchStatus"
          :value="1"
          class="mt-1"
          dense
          hide-details
          label="Sắp ra mắt"
        ></v-checkbox>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">
          Hiển thị trên website
        </div>
        <v-radio-group v-model="selectedSite" class="mt-0" dense hide-details>
          <v-radio label="Tất cả" value="ALL"></v-radio>
          <v-radio
            v-for="(site, index) in ARRAY_LIST_MODEL_SITES"
            :key="index"
            :label="site.text"
            :value="site.id"
          ></v-radio>
          <v-radio label="Bị ẩn" value="NONE"></v-radio>
        </v-radio-group>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Trạng thái</div>
        <v-radio-group
          v-model="selectedStatusDisplay"
          class="mt-0"
          dense
          hide-details
        >
          <v-radio
            v-for="(item, index) in Object.values(PRODUCT_STATUS)"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Tình trạng kinh doanh</div>
        <v-radio-group
          v-model="selectedSaleStatus"
          class="mt-0"
          dense
          hide-details
        >
          <v-radio :value="1" label="Đang kinh doanh"></v-radio>
          <v-radio :value="0" label="Ngừng kinh doanh"></v-radio>
        </v-radio-group>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES } from "@/core/constant";

import { PRODUCT_STATUS } from "@/modules/Goods/constants";

export default {
  props: {
    brandsFilter: {
      type: Array
    },
    status: {
      type: String
    },
    launchStatusFilter: {
      type: Array
    },
    productTypesFilter: {
      type: Array
    },
    saleStatusFilter: {
      type: [Number, String]
    },
    siteFilter: {
      type: String
    }
  },

  data() {
    return {
      ARRAY_LIST_MODEL_SITES,
      LIST_MODEL_SITES,
      PRODUCT_STATUS
    };
  },

  computed: {
    brands() {
      return this.$store.getters["BRAND/allBrands"];
    },
    productTypes() {
      return this.$store.getters["PRODUCT_TYPE/allProductTypes"];
    },
    selectedBrands: {
      get() {
        return this.brandsFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "brandsFilter",
          value: val
        });
      }
    },
    selectedStatusDisplay: {
      get() {
        return this.$props.status;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "status",
          value: val
        });
      }
    },
    selectedLaunchStatus: {
      get() {
        return this.launchStatusFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "launchStatusFilter",
          value: val
        });
      }
    },
    selectedProductsTypes: {
      get() {
        return this.productTypesFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "productTypesFilter",
          value: val
        });
      }
    },
    selectedSaleStatus: {
      get() {
        return this.saleStatusFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "saleStatusFilter",
          value: val
        });
      }
    },
    selectedSite: {
      get() {
        return this.siteFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "siteFilter",
          value: val
        });
      }
    }
  },
  created() {
    this.$store.dispatch("BRAND/getAllBrands");
    this.$store.dispatch("PRODUCT_TYPE/getAllProductTypes");
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
