<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          :options="productSearchOptions"
          :value="searchKey"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>
      <div class="action-row--col">
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          @click="openModalProduct()"
        >
          <v-icon left>mdi-plus</v-icon> Thêm sản phẩm
        </v-btn>
        <v-btn
          class="ml-3 rounded-lg"
          color="primary"
          dark
          depressed
          outlined
          @click="$refs.file.click()"
        >
          <v-icon left>mdi-file-upload-outline</v-icon> Nhập file
        </v-btn>
        <!--<v-btn class="ml-3 rounded-lg" color="primary" dark depressed>
          <v-icon left>mdi-file-download-outline</v-icon> Xuất file
        </v-btn>-->
        <input
          type="file"
          ref="file"
          hidden
          accept=".xlsx, .xls"
          @change="uploadExcelFile()"
        />
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          btn-class="white rounded-lg"
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
        <!-- Start: Action dropdown -->
        <tp-btn-overflow
          v-if="selectedProducts.length > 0"
          btn-class="white rounded-lg ml-3"
          :active-item="false"
          :items="actionList"
          placeholder="Thao tác"
          @select="selectAction($event)"
        ></tp-btn-overflow>
        <!-- End: Action dropdown -->
        <div class="ml-3" v-if="selectedProducts.length > 0">
          Đã chọn <strong>{{ this.selectedProducts.length }}</strong> sản phẩm
        </div>
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          color="primary"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedProducts: {
      type: Array,
      required: true
    },
    selectedShowFilterId: {
      type: Number
    },
    searchKey: {
      type: String
    }
  },
  data() {
    return {
      actionList: [
        { id: "delete", text: "Xóa" }
        // { id: "group", text: "Nhóm sản phẩm" }
      ],
      productSearchOptions: [
        { type: "product/search", label: "Theo mã, tên sản phẩm" }
      ],
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ]
    };
  },
  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    paginationInfo() {
      return this.$store.getters["PRODUCT/paginationInfo"];
    },
    productStatusRequest() {
      return this.$store.getters["PRODUCT/statusRequest"];
    }
  },
  methods: {
    openModalProduct() {
      this.$modal.show({
        name: "modal-product"
      });
    },

    selectAction(val) {
      if (val.id === "delete") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span>Xóa <strong>"${this.selectedProducts.length}"</strong> sản phẩm đã chọn</span>`,
            message:
              "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
            confirmBtn: {
              color: "red accent-2",
              text: "Xóa",
              onClickHandler: async () => {
                const routeQuery = this.$route.query;

                await this.$store.dispatch("PRODUCT/deleteProducts", {
                  data: {
                    ids: this.selectedProducts.map(item => item.id)
                  },
                  routeQuery: {
                    filters: {
                      category_id:
                        typeof routeQuery.category_id === "string"
                          ? [parseInt(routeQuery.category_id)]
                          : typeof routeQuery.category_id === "object"
                          ? routeQuery.category_id.map(item => parseInt(item))
                          : null,
                      brand_id:
                        typeof routeQuery.brand_id === "string"
                          ? [parseInt(routeQuery.brand_id)]
                          : typeof routeQuery.brand_id === "object"
                          ? routeQuery.brand_id.map(item => parseInt(item))
                          : null,
                      ttrm:
                        typeof routeQuery.ttrm === "string"
                          ? [parseInt(routeQuery.ttrm)]
                          : typeof routeQuery.ttrm === "object"
                          ? routeQuery.ttrm.map(item => parseInt(item))
                          : [],
                      ttkd: parseInt(routeQuery.ttkd)
                    },
                    search: routeQuery.search || null,
                    per_page: parseInt(routeQuery.limit),
                    cur_page: parseInt(routeQuery.page)
                  }
                });
                if (
                  this.productStatusRequest.value === "success-deleteProducts"
                ) {
                  this.$emit("updateSelectedProducts", []);
                  // Alert
                  this.$toast.show({
                    name: "toast-action-alert",
                    payload: {
                      message: "Đã xóa thành công"
                    }
                  });
                }
              }
            }
          }
        });
      }
    },

    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    },

    async uploadExcelFile() {
      const excelFile = this.$refs.file.files;
      const formData = new FormData();
      const routeQuery = this.$route.query;

      Array.from(excelFile).forEach(f => {
        formData.append("file", f);
      });
      // Import request
      await this.$store.dispatch("PRODUCT/importExcel", {
        formData: formData,
        routeQuery: {
          filters: {
            category_id:
              typeof routeQuery.category_id === "string"
                ? [parseInt(routeQuery.category_id)]
                : typeof routeQuery.category_id === "object"
                ? routeQuery.category_id.map(item => parseInt(item))
                : null,
            brand_id:
              typeof routeQuery.brand_id === "string"
                ? [parseInt(routeQuery.brand_id)]
                : typeof routeQuery.brand_id === "object"
                ? routeQuery.brand_id.map(item => parseInt(item))
                : null,
            ttrm:
              typeof routeQuery.ttrm === "string"
                ? [parseInt(routeQuery.ttrm)]
                : typeof routeQuery.ttrm === "object"
                ? routeQuery.ttrm.map(item => parseInt(item))
                : [],
            ttkd: parseInt(routeQuery.ttkd)
          },
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Check request status
      if (this.productStatusRequest.value === "success-importExcel") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã nhập file sản phẩm"
          }
        });
      }
      // Reset ref
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
