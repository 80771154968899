<template>
  <v-data-table
    v-model="selected"
    class="tp-table-scroll datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="productStatusRequest.value === 'loading-getProducts'"
    loading-text="Đang tải dữ liệu"
    :items="products"
    item-key="id"
    show-select
    @click:row="openModalProduct($event)"
  >
    <!--<template v-slot:[`header.data-table-select`]="{ on, props }">
      <v-simple-checkbox
        color="primary"
        v-bind="props"
        v-on="on"
      ></v-simple-checkbox>
    </template>-->

    <!--<template v-slot:[`item.data-table-select`]="{ isSelected, select }">
      <v-simple-checkbox
        color="primary"
        :value="isSelected"
        @input="select($event)"
      ></v-simple-checkbox>
    </template>-->

    <template v-slot:[`item.code`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click="copyToClipboard(item.code)"
          >
            {{ item.code }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.ttkd`]="{ item }">
      <v-icon v-if="item.ttkd" size="16" color="green">mdi-check</v-icon>
      <v-icon v-else size="16" color="red lighten-3">mdi-close</v-icon>
    </template>

    <template v-slot:[`item.ttrm`]="{ item }">
      <v-icon v-if="item.ttrm" size="16" color="green">mdi-check</v-icon>
      <v-icon v-else size="16" color="red lighten-3">mdi-close</v-icon>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <div>
        {{ PRODUCT_STATUS[item.status].label }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { LIST_MODEL_TYPES } from "@/core/constant";

import { PRODUCT_STATUS } from "@/modules/Goods/constants";

export default {
  props: {
    selectedProducts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      PRODUCT_STATUS,
      headers: [
        {
          text: "Mã sản phẩm",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Loại sản phẩm",
          align: "start",
          sortable: false,
          value: "category_name"
        },
        {
          text: "Thương hiệu",
          align: "start",
          sortable: false,
          value: "brand_name"
        },
        {
          text: "Kinh doanh",
          align: "center",
          sortable: false,
          value: "ttkd"
        },
        {
          text: "Sắp ra mắt",
          align: "center",
          sortable: false,
          value: "ttrm"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "status"
        }
      ]
    };
  },
  computed: {
    products() {
      return this.$store.getters["PRODUCT/products"];
    },
    productStatusRequest() {
      return this.$store.getters["PRODUCT/statusRequest"];
    },
    selected: {
      get() {
        return this.selectedProducts;
      },
      set(val) {
        this.$emit("updateSelectedProducts", val);
      }
    }
  },
  methods: {
    getLaunchStatus(val) {
      if (val === 0) return null;
      else if (val === 1) return "cyan lighten-4";
    },

    async openModalProduct(product) {
      await this.$store.dispatch("PRODUCT/getProductById", product.id);

      if (this.productStatusRequest.value === "success-getProductById") {
        await this.$store.dispatch(
          "PRODUCT_OPTION/getProductOptionsByProductId",
          product.id
        );

        // Get  model site configs of product
        const response = await this.$store.dispatch(
          "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
          {
            modelId: product.id,
            modelType: LIST_MODEL_TYPES.PRODUCT
          }
        );

        await this.$store.dispatch(
          "PRODUCT/setProductModelSiteConfigs",
          response
        );

        this.$modal.show({
          name: "modal-product"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
