<template>
  <div class="d_flex">
    <v-overlay
      v-show="
        productStatusRequest.value === 'loading-getProductById' ||
          productOptionStatusRequest.value ===
            'loading-getProductOptionsByProductId'
      "
      opacity="0.5"
    >
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :brands-filter="brandsFilter"
        :launch-status-filter="launchStatusFilter"
        :product-types-filter="productTypesFilter"
        :sale-status-filter="saleStatusFilter"
        :site-filter="siteFilter"
        :status="status"
        @updateFilterProperty="updateFilterProperty($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :search-key="searchKey"
        :selected-products="selectedProducts"
        :selected-show-filter-id="itemPerPage"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
        @updateSelectedProducts="selectedProducts = $event"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-products
          :selected-products="selectedProducts"
          @updateSelectedProducts="selectedProducts = $event"
        ></data-table-products>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
  </div>
</template>

<script>
import DataTableProducts from "./components/DataTableProducts";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";
import { updateFilterProperty } from "@/core/composables";

export default {
  components: {
    TopAction,
    DataTableProducts,
    FilterArea
  },

  data() {
    return {
      brandsFilter: null,
      currentPage: 1,
      itemPerPage: 50,
      launchStatusFilter: null,
      productTypesFilter: null,
      saleStatusFilter: 1,
      selectedProducts: [],
      searchKey: null,
      siteFilter: "ALL",
      status: "ALL"
    };
  },

  computed: {
    productStatusRequest() {
      return this.$store.getters["PRODUCT/statusRequest"];
    },
    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    }
  },

  watch: {
    "$route.query"(val) {
      this.getProductsFromRouteQuery(val);
    }
  },

  async created() {
    const route = this.$route;

    await this.getProductsFromRouteQuery(route.query);
  },

  methods: {
    async pushRouter() {
      await this.$router.push({
        name: "goods_products",
        query: {
          category_id:
            this.productTypesFilter && this.productTypesFilter.length > 0
              ? this.productTypesFilter
              : undefined,
          brand_id:
            this.brandsFilter && this.brandsFilter.length > 0
              ? this.brandsFilter
              : undefined,
          site: this.siteFilter,
          status:
            this.status && this.status !== "ALL" ? this.status : undefined,
          ttrm: this.launchStatusFilter ? this.launchStatusFilter : undefined,
          ttkd: this.saleStatusFilter,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getProductsFromRouteQuery(query) {
      if (query.page) {
        // Set filtered brands
        this.brandsFilter =
          typeof query.brand_id === "string"
            ? [parseInt(query.brand_id)]
            : typeof query.brand_id === "object"
            ? query.brand_id.map(item => parseInt(item))
            : null;
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set status display
        this.status = query.status || "ALL";
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set filtered launch status
        this.launchStatusFilter = query.ttrm ? parseInt(query.ttrm) : null;
        // Set filtered product types
        this.productTypesFilter =
          typeof query.category_id === "string"
            ? [parseInt(query.category_id)]
            : typeof query.category_id === "object"
            ? query.category_id.map(item => parseInt(item))
            : null;
        // Set filtered sale status
        this.saleStatusFilter = parseInt(query.ttkd);
        // Set search key
        this.searchKey = query.search || null;

        this.siteFilter = query.site || "ALL";

        // Get products
        await this.$store.dispatch("PRODUCT/getProducts", {
          filter: {
            category_id: this.productTypesFilter,
            brand_id: this.brandsFilter,
            ttrm: this.launchStatusFilter ? [this.launchStatusFilter] : null,
            site: this.siteFilter,
            status: this.status && this.status !== "ALL" ? [this.status] : null,
            ttkd: [this.saleStatusFilter]
          },
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProducts = [];
      // Push router query
      this.pushRouter();
    },

    updateFilterProperty(data) {
      updateFilterProperty(this, data.filterProperty, data.value);
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProducts = [];
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
